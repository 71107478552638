export interface ICookies {
    getCookie(name: string): void;

    setCookie(name: string, value: string, props: any): void;
}

class Cookies implements ICookies {

    public getCookie(name: string): string | null {
        const match = document.cookie.match(RegExp('(?:^|;\\s*)' + this.getReplacedName(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    public setCookie(name: string, value: string, props: any): void {
        const params = props || {};
        let expires = params.expires;

        if (typeof expires === 'number' && expires) {
            const date = new Date();
            date.setTime(date.getTime() + expires * 1000);
            expires = date;
            params.expires = date;
        }

        if (expires?.toUTCString) {
            params.expires = expires.toUTCString();
        }

        const newDefaultCookie = `${name}=${encodeURIComponent(value)}`;

        document.cookie = Object.entries(params).reduce((acc, parameter) => {
            const parameters = `${parameter[0]}=${parameter[1]}`;
            return `${acc}; ${parameters}`;
        }, newDefaultCookie);
    }

    private getReplacedName(name: string): string {
        return name.replace(/([.*+?^${}()|[]\/\\])/g, '\\$1');
    }
}

export { Cookies };
