import { ICompany } from '../../../features/organizationsMap/namespace';
import { IFormattedBoundsCoords } from '../../../shared/view/components/YandexMap/YandexMap';
import { SubscribeType } from '../../../shared/types/models';
import { ISelectedFilters } from '../../filters/namespace';

import { IServerCompanyForMap } from '../types';
import { convertFiltersToRequestString } from './companiesList';

function convertServerSubscribeType(type: number): SubscribeType {
    const subscription: Record<number, SubscribeType> = { 1: 'free', 2: 'info', 3: 'promo', 4: 'business', 5: 'vip' };
    return subscription[type];
}

export function convertServerOrganizationsMap(organizationsMap: IServerCompanyForMap[]): ICompany[] {

    return organizationsMap.map(serverCompany => {
        const { title, tizer, address, coords, url, status } = serverCompany;
        return {
            name: title,
            description: tizer,
            address,
            coords,
            url,
            subscribeType: convertServerSubscribeType(status)
        };
    });
}

export function convertOrganizationsMapRequestToRequestString(boundsCoords: IFormattedBoundsCoords, filters: ISelectedFilters, sectionParentId: number): string {
    const { x1, y1, x2, y2 } = boundsCoords;
    const parentID = sectionParentId ? `&parent_id=${sectionParentId}` : '';
    const filtersParameters = convertFiltersToRequestString(filters);

    return ''.concat(`x=${x1},`, `${y1}&y=${x2},`, `${y2}${parentID}${filtersParameters}`);
}
