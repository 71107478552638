import { makeCommunicationActionCreators } from 'redux-make-communication';
import * as NS from '../../namespace';

export const { execute: loadCompanies, success: loadCompaniesCompleted, fail: loadCompaniesFailed } =
    makeCommunicationActionCreators<NS.ILoadCompanies, NS.ILoadCompaniesCompleted, NS.ILoadCompaniesFailed>(
        'COMPANIES_LIST:LOAD_COMPANIES',
        'COMPANIES_LIST:LOAD_COMPANIES_COMPLETED',
        'COMPANIES_LIST:LOAD_COMPANIES_FAILED'
    );

export const { execute: sortCompanies, success: sortCompaniesCompleted, fail: sortCompaniesFailed } =
    makeCommunicationActionCreators<NS.ISortCompanies, NS.ISortCompaniesCompleted, NS.ISortCompaniesFailed>(
        'COMPANIES_LIST:SORT_COMPANIES',
        'COMPANIES_LIST:SORT_COMPANIES_COMPLETED',
        'COMPANIES_LIST:SORT_COMPANIES_FAILED'
    );

export const { execute: filterCompanies, success: filterCompaniesCompleted, fail: filterCompaniesFailed } =
    makeCommunicationActionCreators<NS.IFilterCompanies, NS.IFilterCompaniesCompleted, NS.IFilterCompaniesFailed>(
        'COMPANIES_LIST:FILTER_COMPANIES',
        'COMPANIES_LIST:FILTER_COMPANIES_COMPLETED',
        'COMPANIES_LIST:FILTER_COMPANIES_FAILED'
    );

export const { execute: loadFavoriteCompanies, success: loadFavoriteCompaniesCompleted, fail: loadFavoriteCompaniesFailed } =
    makeCommunicationActionCreators<NS.ILoadFavoriteCompanies, NS.ILoadFavoriteCompaniesCompleted, NS.ILoadFavoriteCompaniesFailed>(
        'COMPANIES_LIST:LOAD_FAVORITE_COMPANIES',
        'COMPANIES_LIST:LOAD_FAVORITE_COMPANIES_COMPLETED',
        'COMPANIES_LIST:LOAD_FAVORITE_COMPANIES_FAILED'
    );

export const { execute: addCompanyToFavorite, success: addCompanyToFavoriteCompleted, fail: addCompanyToFavoriteFailed } =
    makeCommunicationActionCreators<NS.IAddCompanyToFavorite, NS.IAddCompanyToFavoriteCompleted, NS.IAddCompanyToFavoriteFailed>(
        'COMPANIES_LIST:ADD_COMPANY_TO_FAVORITE',
        'COMPANIES_LIST:ADD_COMPANY_TO_FAVORITE_COMPLETED',
        'COMPANIES_LIST:ADD_COMPANY_TO_FAVORITE_FAILED'
    );

export const { execute: deleteCompanyFromFavorite, success: deleteCompanyFromFavoriteCompleted, fail: deleteCompanyFromFavoriteFailed } =
    makeCommunicationActionCreators<NS.IDeleteCompanyFromFavorite, NS.IDeleteCompanyFromFavoriteCompleted, NS.IDeleteCompanyFromFavoriteFailed>(
        'COMPANIES_LIST:DELETE_COMPANY_FROM_FAVORITE',
        'COMPANIES_LIST:DELETE_COMPANY_FROM_FAVORITE_COMPLETED',
        'COMPANIES_LIST:DELETE_COMPANY_FROM_FAVORITE_FAILED'
    );

export const { execute: loadNoteFromServer, success: loadNoteFromServerCompleted, fail: loadNoteFromServerFailed } =
    makeCommunicationActionCreators<NS.ILoadNoteFromServer, NS.ILoadNoteFromServerCompleted, NS.ILoadNoteFromServerFailed>(
        'COMPANIES_LIST:LOAD_NOTE_FROM_SERVER',
        'COMPANIES_LIST:LOAD_NOTE_FROM_SERVER_COMPLETED',
        'COMPANIES_LIST:LOAD_NOTE_FROM_SERVER_FAILED'
    );

export const { execute: saveNoteOnServer, success: saveNoteOnServerCompleted, fail: saveNoteOnServerFailed } =
    makeCommunicationActionCreators<NS.ISaveNoteOnServer, NS.ISaveNoteOnServerCompleted, NS.ISaveNoteOnServerFailed>(
        'COMPANIES_LIST:SAVE_NOTE_ON_SERVER',
        'COMPANIES_LIST:SAVE_NOTE_ON_SERVER_COMPLETED',
        'COMPANIES_LIST:SAVE_NOTE_ON_SERVER_FAILED'
    );

export const { execute: sendFeedback, success: sendFeedbackCompleted, fail: sendFeedbackFailed } =
    makeCommunicationActionCreators<NS.ISendFeedback, NS.ISendFeedbackCompleted, NS.ISendFeedbackFailed>(
        'COMPANIES_LIST:SEND_FEEDBACK_MESSAGE',
        'COMPANIES_LIST:SEND_FEEDBACK_MESSAGE_COMPLETED',
        'COMPANIES_LIST:SEND_FEEDBACK_MESSAGE_FAILED'
    );
