interface AuthenticatedStatus {
    type: string;
    payload: boolean;
}

export function getAuthenticatedStatus(): AuthenticatedStatus {
    const isAuthenticated = window.isAuthenticated;

    return {
        type: 'APP:GET_AUTHENTICATED_STATUS',
        payload: isAuthenticated
    };
}
